@import "../../styles/variables";

.chooseButton {
    width: 115px;
    font-size: 1rem;
}

.fileList {
    max-height: 350px;
    border: 1px solid $color-white;
    border-radius: 5px;
    padding: 5px;
    margin-top: 1rem;
    background-color: $color-white;
}
